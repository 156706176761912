import Eksoda from './Eksoda'
import Layout from './Layout';
const Expenses_Display = () =>
{
    return(
        <Layout>
            <Eksoda></Eksoda>
        </Layout>
    )
}
export default Expenses_Display;