import React,{useEffect} from 'react'
import Layout from '../Layout'
import FormProfileEkxorimenoTimologio from '../../components/ekxwrimena_timologia_component/FormProfileEkxorimenoTimologio'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../../features/authSlice'

const ProfileEkxorimenoTimologio = () =>
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isError} = useSelector((state=>state.auth));
  
    useEffect(()=>{
        dispatch(getMe());
    },[dispatch]);
  
    useEffect(()=>{
        if(isError){
            navigate("/");
        }
    },[isError,navigate]);
    return (
      <Layout>
          <FormProfileEkxorimenoTimologio/>
      </Layout>
    )
}

export default ProfileEkxorimenoTimologio;